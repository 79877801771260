export const API = {
  url: "https://shum.brainster.co/",
  paths: {
    images: "https://shum.brainster.co/api/images/",
    webinars: "https://shum.brainster.co/api/webinars",
    stories: "https://shum.brainster.co/api/successfulstories",
    blogs: "https://shum.brainster.co/api/blogs",
    music: "https://shum.brainster.co/api/music",
    icons: "https://shum.brainster.co/icons/",
  },
};
